<template>
  <el-dialog title="详情" :visible.sync="isDetailDialog" width="1100px" @close="closeAuditDialog">
    <ch-form style="width: 100%;" ref="form" :model="form" :props="{ labelWidth: '130px'}"  :splice="[14]"
             :render-option="formRenderOption"></ch-form>
  </el-dialog>
</template>

<script>
import dayjs from 'dayjs'
export default {
  data() {
    return {
      isDetailDialog: false,
      form: {}
    }
  },
  computed: {
    formRenderOption: function () {
      return [
        {type: 'text', label: '适用商家：', prop: 'companyNameSet'},
        {type: 'text', label: '参与商品：', prop: 'goodsNameSet'},
        {type: 'text', label: '抵扣券名称：', prop: 'name'},
        {type: 'text', label: '是否可叠加用券：', prop: 'stackableCoupon',yes: '可叠加',no: '不可叠加'},
        {type: 'text', label: '是否可叠加优惠：', prop: 'stackableDiscount',yes: '可叠加',no: '不可叠加'},
        {type: 'text', label: '使用门槛：', prop: 'threshold',yes: '不限制',no: '满额使用'},
        {type: 'text', label: '满额使用：', prop: 'thresholdValue',sign: '元',no: '0'},
        {type: 'text', label: '抵扣券面值：', prop: 'amount',sign: '元',no: '0'},
        {type: 'text', label: '使用门槛：', prop: 'validityType',yes: '领券后几天可用',no: '自定义'},
        {type: 'text', label: '有效天数：', prop: 'validDays',show: this.form.validityType === 1,sign: '天'},
        {type: 'text', label: '有效起始时间：', prop: 'validTime',show: this.form.validityType === 0,},
        {type: 'text', label: '是否公开：', prop: 'isPrivate', yes: '公开', no: '私有'},
        {type: 'text', label: '分享人员：', prop: 'shareUserPhone'},
        {type: 'text', label: '分享佣金：', prop: 'shareBonus', sign: '元'},
        {type: 'text', label: '使用规则说明：', prop: 'rules', },
        {type: 'image', label: '分享页面背景图：', src: [this.form.backgroundImageUrl]},
        {type: 'text', label: '分享页面标题：', prop: 'title'},
        {type: 'text', label: '分享标题颜色：', prop: 'titleColor'},
        {type: 'text', label: '分享卡片标题：', prop: 'shareTitle'},
        {type: 'image', label: '分享卡片图片：', src: [this.form.shareImageUrl]},
      ]
    }
  },
  methods: {
    open(row){
      this.isDetailDialog = true;
      this.form = JSON.parse(row);
      this.form.validTime = dayjs(this.form.validStart) + '至' + dayjs(this.form.validEnd)
    },
    closeAuditDialog() {
      this.isDetailDialog = false;
    }
  },
}
</script>


<style scoped lang="scss">

</style>